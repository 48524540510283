<template>
  <div class="m1">
    <div class="m1-h">
      <h2><a href="javascript:;" @click="back()"><i class="el-icon-arrow-left"></i></a>  信息详情</h2>
    </div>
    <div class="m1-r1">
      <div class="m1-r1c1">
        <h2>基本信息</h2>
        <ul class="m1-ul">
          <li><label>信息ID</label><span>{{data.id}}</span> <label for="">用户ID</label><span>{{data.user_id}}</span></li>
          <li><label>电话获取次数</label><span>{{data.phone_count}}</span> <label for="">发布时间</label><span>{{data.publish_time}}</span></li>
        </ul>
        <el-form ref="form" :model="addForm" label-width="80px">
          <el-form-item label="手机号">
            <el-input v-model="addForm.phone" placeholder="请输入手机号" style="width: 189px;"></el-input>
          </el-form-item>
          <el-form-item label="工种">
            <el-select @change="changeSubType(addForm.type_id)" v-model="addForm.type_id" placeholder="请选择" style="margin-right:14px">
              <el-option v-for="item in tree" :label="item.name" :value="item.id"></el-option>
            </el-select>
            <el-select v-model="addForm.sub_type_id" placeholder="请选择">
              <el-option v-for="item in subTree" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="地区">
            <el-select @change="changeCity(addForm.province_id)" v-model="addForm.province_id" placeholder="请选择省份" style="margin-right:14px">
              <el-option v-for="item in areaLists" :label="item.label" :value="item.value"></el-option>
            </el-select>
            <el-select v-model="addForm.city_id" placeholder="请选择城市">
              <el-option v-for="item in citys" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="正文">
            <el-input v-model="addForm.content" placeholder="请输入正文" type="textarea" :rows="5"></el-input>
          </el-form-item>
          
          
        </el-form>
      </div>
      <div class="m1-r1c2">
        <h2>原始信息</h2>
        <ul class="m1-ul">
          <li><label>平台</label><span>{{data.crawl.type_name}}</span> <label for="">抓取账号</label><span>{{data.crawl.name}}</span></li>
          <li><label>原始字段</label>
            <el-input v-model="data.crawl.content" placeholder="请输入正文" type="textarea" :rows="15"></el-input>
          </li>
        </ul>
      </div>
    </div>
    <h2>审核信息</h2>
    <ul class="m1-ul">
      <li>
        <label for="">审核状态</label>
        <el-radio-group v-model="data.audit_status" style="margin-right:20px">
          <el-radio :label="1">通过</el-radio>
          <el-radio :label="2">拒绝</el-radio>
        </el-radio-group>
        <label for="">审核时间</label>
        <span>{{data.audit_time}}</span>
        <label for="">审核人</label>
        <span>{{data.audit_name}}</span>
      </li>
    </ul>
    
  </div>
</template>

<script>
import {
  detail, save, tree, areaLists
} from '@/api/employment';
import moment from 'moment'
export default {
  name: 'index',
  data() {
    return {
      data: {},
      addForm: {
        user_key_id: '',
        type_id: '',
        sub_type_id: '',
        province_id: '',
        city_id: '',
        phone: '',
        content: ''
      },
      tree: [],
      subTree: [],
      areaLists: [],
      citys: [],
    }
  },
  computed: {
  },
  created() {
    
  },
  mounted(){
    this.getTree()
    this.getAreaLists()
  },
  methods: {
    getTree () {
      tree().then(res =>{
        this.tree = res.data.data
      })
    },
    getAreaLists () {
      areaLists().then(res =>{
        this.areaLists = res.data.data
        this.getDetail()
      })
    },
    getDetail () {
      detail({
        id: this.$route.query.id
      }).then(res =>{
        let data = res.data.data;
        this.data = data
        this.data.crawl.content = JSON.stringify(this.data.crawl.content)
        this.addForm = {
          id: data.id,
          user_key_id: data.user_key_id,
          type_id: data.type_id,
          sub_type_id: data.sub_type_id,
          province_id: data.province_id,
          city_id: data.city_id,
          phone: data.phone,
          content: data.content,
          audit_status: data.audit_status,
        }
        this.tree.forEach((item) => {
          if (item.id == data.type_id) {
            this.subTree = item.children
          }
        })
        this.areaLists.forEach((item) => {
          if (item.value == data.province_id) {
            this.citys = item.children
          }
        })
         
      })
    },
    back() {
      history.back()
    }
  },
}
</script>
<style scoped lang="scss">
.m1-h{
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.m1-r1{
  display: flex;
  justify-content: space-between
}
.m1-r1>div{
  width: 49%

}
.m1-ul li{
  list-style: none;
  margin: 20px 0;
}
.m1-ul li>label{
  display: inline-block;
  vertical-align: top;
  width: 7em;
  color: #999;
}
.m1-ul li>span{
  display: inline-block;
  width: 12em;
}
</style>
